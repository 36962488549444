import React from "react";

import { SectionStyled, SectionHeadStyled, SectionBlockStyled } from "./styles";
import { Typo4 } from "./../Typography";
import theme from "../../themes/theme";

interface IProps {
  heading?: React.ReactNode;
  desc?: React.ReactNode;
  footer?: React.ReactNode;
  pbHead?: string[];
  pbDesc?: string[];
  pbBody?: string[];
  ptBody?: string[];
  bodyBg?: string;
  isMobileBorder?: boolean;
  id?: string;
}

const Section: React.FC<IProps> = ({
  id,
  pbHead,
  pbDesc,
  ptBody,
  pbBody,
  heading,
  desc,
  children,
  footer,
  bodyBg,
  isMobileBorder,
}) => (
  <SectionStyled {...{ id }}>
    {heading && (
      <SectionHeadStyled pb={pbHead} {...{ isMobileBorder }}>
        {heading}
      </SectionHeadStyled>
    )}

    {desc && (
      <SectionBlockStyled desc pb={pbDesc}>
        <Typo4 fontWeight={theme.fontWeight.light}> {desc}</Typo4>
      </SectionBlockStyled>
    )}
    <SectionBlockStyled pt={ptBody} pb={pbBody} bg={bodyBg}>
      {children}
    </SectionBlockStyled>
    {footer && <SectionBlockStyled footer>{footer}</SectionBlockStyled>}
  </SectionStyled>
);

export default Section;
