import React from "react";

import {
  BannerWrapperStyled,
  BannerStyled,
  BannerMediaStyled,
  BannerContentStyled,
} from "./styles";

import InfoBanner from "./../InfoBanner";
import DottedBg from "./../DottedBg";

import Image from "./../Image";
import { FluidObject } from "gatsby-image";

interface IProps {
  image?: FluidObject[] | FluidObject;
  content?: React.ReactNode;
  altText?: string;
}

const Banner: React.FC<IProps> = ({ image, content, altText }) => {
  return (
    <BannerWrapperStyled>
      <BannerStyled>
        <BannerMediaStyled>
          {image && <Image fluid={image} alt={altText || ""} />}
          <DottedBg />
        </BannerMediaStyled>
        {content && (
          <BannerContentStyled>
            <InfoBanner>{content}</InfoBanner>
          </BannerContentStyled>
        )}
      </BannerStyled>
    </BannerWrapperStyled>
  );
};

export default Banner;
