import React from "react";
import styled, { css } from "styled-components";

import { media } from "./../../common/MediaQueries";
import dottedBg from "./../../assets/images/dotted-mask.png";
import dottedDarkBg from "./../../assets/images/dotted-mask-dark.png";
import dottedBlueBg from "./../../assets/images/dotted-mask-blue.png";

const DottedBgStyled = styled.div`
  display: none;

  @media ${media.phone} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${dottedBg});
    background-position: 50% 50%;
    background-size: initial;
    z-index: 1;

    ${({ isDark }) =>
      isDark &&
      css`
        background-image: url(${dottedDarkBg});
      `}

    ${({ isBlue }) =>
      isBlue &&
      css`
        background-image: url(${dottedBlueBg});
      `}
  }
`;

interface IProps {
  isDark?: boolean;
  isBlue?: boolean;
}

const DottedBg: React.FC<IProps> = ({ isDark, isBlue }) => (
  <DottedBgStyled {...{ isDark, isBlue }} />
);

export default DottedBg;
