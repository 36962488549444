import React from "react";
import styled, { css } from "styled-components";

import { media } from "./../common/MediaQueries";
import dottedDarkBg from "./../assets/images/dotted-mask-dark.png";
import dottedDarkSmBg from "./../assets/images/dotted-mask-dark-sm.png";
import dottedBlueSmBg from "./../assets/images/dotted-mask-blue-sm.png";

interface ContentWrapperStyledProps {
  isBlue?: boolean;
}

const ContentWrapperStyled = styled.div<ContentWrapperStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: -33px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${dottedDarkBg});
    background-position: 50% 0%;
    background-size: initial;
    z-index: 1;

    ${({ isBlue }) =>
      isBlue &&
      css`
        background-image: url(${dottedBlueSmBg});
        top: -5px;
      `}

    @media ${media.tablet} {
      background-image: url(${dottedDarkSmBg});
      ${({ isBlue }) =>
        isBlue &&
        css`
          background-image: url(${dottedBlueSmBg});
        `}
    }
  }
`;

interface IProps {
  children: React.ReactNode;
  isBlue?: boolean;
}

const ContentWrapper: React.FC<IProps> = ({ children, isBlue }) => (
  <ContentWrapperStyled {...{ isBlue }}>{children}</ContentWrapperStyled>
);

export default ContentWrapper;
