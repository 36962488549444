import React from "react";
import SEO from "../Seo";
import Layout from "../Layout";
import Container from "../Container";
import ContentWrapper from "../ContentWrapper";
import Section from "../Section";
import Inner from "../Inner";
import Banner from "../Banner";
import { Heading6 } from "../Typography";
import { Flex, FlexItem } from "../Flex";
import Wysiwyg from "../Wysiwyg";
import { FluidObject } from "gatsby-image";

interface Props {
  desktopImage: FluidObject;
  mobileImage: FluidObject;
  imageAlt: string;
  title: string;
  content: string;
  seoTitle: string;
  seoDesc: string;
  seoUrl: string;
  seoImageUrl: string;
  smallerLists?: boolean;
}

const Page = (props: Props) => {
  const {
    title,
    mobileImage,
    imageAlt,
    desktopImage,
    content,
    seoTitle,
    seoUrl,
    seoImageUrl,
    seoDesc,
    smallerLists,
  } = props;

  return (
    <Layout
      headerLocation=""
      footerBorderOnMobile={true}
    >
      <SEO
        title={seoTitle}
        description={seoDesc}
        url={seoUrl}
        image={seoImageUrl}
      />
      <ContentWrapper isBlue>
        <Container>
          <Inner noOffsetMobile>
            <Section>
              <Banner
                image={[mobileImage, desktopImage]}
                altText={imageAlt}
                content={
                  title && (
                    <Flex flexDirection={["column", null, "row"]}>
                      <FlexItem
                        width={["100%", null, "60%"]}
                        pb={["10px", "", "0"]}
                        pt={["16px", "", "60px"]}
                      >
                        <Container width={["100%"]} pr={["", "62px"]}>
                          <Heading6 as="h1" fontWeight="700">
                            {title}
                          </Heading6>
                        </Container>
                      </FlexItem>
                    </Flex>
                  )
                }
              />
            </Section>
          </Inner>
        </Container>
        <Container
          zIndex={["1", "1", "auto"]}
          bg={["white", "white", "transparent"]}
          pt={["25px", "0px"]}
          pb="0"
        >
          <Inner>
            <Section>
              <Inner bg="white" isRelative isSmall noOffsetMobile>
                <Wysiwyg
                  smallerHeadings={true}
                  smallerLists={smallerLists}
                  skipMarginYMargins={true}
                >
                  <div
                    id="wysiwyg-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </Wysiwyg>
              </Inner>
            </Section>
          </Inner>
        </Container>
      </ContentWrapper>
    </Layout>
  );
};

export default Page;
