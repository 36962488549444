import React, { ReactNode } from "react";

import { WysiwygStyled } from "./styles";

interface Props {
  children: ReactNode;
  isThinTypo?: boolean;
  wide?: boolean;
  mediumType?: boolean;
  skipMarginYMargins?: boolean;
  smallerHeadings?: boolean;
  smallerLists?: boolean;
}

const Wysiwyg: React.FC<Props> = (props: Props) => {
  const {
    children,
    mediumType,
    isThinTypo,
    wide,
    skipMarginYMargins,
    smallerHeadings,
    smallerLists,
  } = props;

  return (
    <WysiwygStyled
      isThinTypo={isThinTypo}
      mediumType={mediumType}
      wide={wide}
      skipMarginYMargins={skipMarginYMargins}
      smallerHeadings={smallerHeadings}
      smallerLists={smallerLists}
    >
      {children}
    </WysiwygStyled>
  );
};

export default Wysiwyg;
