import React from "react";

import { InfoBannerStyled } from "./styles";
import Inner from "./../Inner";

const InfoBanner: React.FC = ({ children }) => {
  return (
    <InfoBannerStyled>
      <Inner isSmall>{children}</Inner>
    </InfoBannerStyled>
  );
};

export default InfoBanner;
