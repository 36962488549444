import React from "react";
import { graphql } from "gatsby";
import { FixedObject, FluidObject } from "gatsby-image";
import PageComponent from "../components/Page";

interface Page {
  heading: string;
  content: string;
  slug: string;
  desktopHeroImage: { localFile: FluidObject };
  mobileHeroImage: { localFile: FluidObject };
  seo?: {
    title: string;
    description: string;
    image: { localFile: FixedObject };
  };
}

interface Props {
  data: {
    page: Page;
  };
}

export default function Page(props: Props) {
  const { data } = props;

  const { page } = data;

  const {
    heading,
    slug,
    content,
    desktopHeroImage,
    mobileHeroImage,
    seo,
  } = page;

  const { title: seoTitle, image: seoImage, description: seoDesc } = seo || {};

  return (
    <PageComponent
      title={heading}
      desktopImage={desktopHeroImage.localFile}
      mobileImage={mobileHeroImage.localFile}
      content={content}
      imageAlt=""
      seoTitle={seoTitle || heading}
      seoDesc={seoDesc || ""}
      seoUrl={`https://${process.env.GATSBY_PUBLIC_DOMAIN}/${slug}`}
      seoImageUrl={
        seoImage?.localFile?.src || desktopHeroImage?.localFile?.src || ""
      }
      smallerLists={true}
    />
  );
}

export const pageQuery = graphql`
  query CustomPageQuery($slug: String!) {
    page: strapiPages(slug: { eq: $slug }) {
      heading
      content
      slug
      desktopHeroImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              width: 1400
              quality: 70
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      mobileHeroImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              width: 1400
              quality: 70
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      seo {
        title
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                height: 630
                quality: 100
                placeholder: NONE
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`;
