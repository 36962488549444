import styled from 'styled-components';

import { media } from './../../common/MediaQueries';

const BannerWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white};
`;

const BannerStyled = styled.div`
  width: 100%;
`;

const BannerMediaStyled = styled.div`
  position: relative;
  height: 294px;
  @media ${media.phone} {
    height: 408px;
  }
`;

const BannerContentStyled = styled.div`
  position: relative;
  z-index: 1;
  @media ${media.phone} {
    margin: 0 60px;
    top: -68px;
  }
`;

export { BannerWrapperStyled, BannerStyled, BannerMediaStyled, BannerContentStyled };
