import styled, { css } from 'styled-components';

import { transition } from './../../common/mixins';
import { customMedia, media } from './../../common/MediaQueries';

interface WysiwygStyledProps {
  wide?: boolean;
  isThinTypo?: boolean;
  mediumType?: boolean;
  skipMarginYMargins?: boolean;
  smallerHeadings?: boolean;
  smallerLists?: boolean;
}

const WysiwygStyled = styled.div<WysiwygStyledProps>`
  width: 100%;

  strong,
  b {
    font-weight: 700;
  }

  *:not([class]) {
    font-size: 1em;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.greyDark};
    word-break: break-word;
    &:not(:last-child) {
      margin-bottom: 0.714em;
    }
    br:not([class]) {
      margin-bottom: 0;
    }
  }

  ${({ skipMarginYMargins }) =>
    skipMarginYMargins &&
    css`
      #wysiwyg-content > *:first-child:not([class]) {
        margin-top: 0;
      }

      #wysiwyg-content > *:last-child:not([class]) {
        margin-bottom: 0;
      }
    `}

  img {
    margin: 1rem 0;
    height: auto;
  }

  a:not([class]) {
    ${transition};
    line-height: 1;
    transition: background-size 0.3s;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue};
    &:hover {
      opacity: 0.8;
    }
  }

  h1:not([class]),
  h2:not([class]),
  h3:not([class]) {
    color: ${({ theme }) => theme.colors.blueDark};
  }

  h4:not([class]) {
    color: ${({ theme }) => theme.colors.blueLight};
  }

  h5:not([class]),
  h6:not([class]) {
    color: ${({ theme }) => theme.colors.main};
  }

  h1:not([class]),
  h2:not([class]),
  h3:not([class]),
  h4:not([class]),
  h5:not([class]),
  h6:not([class]) {
    margin-top: 1.5em;
    margin-bottom: 1em;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    line-height: 1.2;

    * {
      font-size: inherit;
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    }
  }

  h1:not([class]),
  h1:not([class]) * {
    font-size: 2.285em;
    @media ${media.phone} {
      font-size: 3.857em;
    }
  }

  h2:not([class]),
  h2:not([class]) * {
    font-size: 1.428em;
    @media ${media.phone} {
      font-size: 2.142em;
    }
  }

  h3:not([class]),
  h3:not([class]) * {
    font-size: 1.285em;
    @media ${media.phone} {
      font-size: 1.714em;
    }
  }

  h4:not([class]),
  h4:not([class]) *,
  h5:not([class]),
  h5:not([class]) *,
  h6:not([class]),
  h6:not([class]) * {
    font-size: 1.285em;
    @media ${media.phone} {
      font-size: 1.428em;
    }
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    margin-left: 2rem;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul li {
    padding-left: 1em;
    text-indent: -0.7em;
  }

  ul li {
    &::before {
      content: '•';
      position: relative;
      right: 8px;
      color: ${({ theme }) => theme.colors.watermelon};
      font-size: 1.571em;
      line-height: 1;
    }
  }

  strong:not([class]) {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }

  em:not([class]) {
    font-style: italic;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }

  table:not([class]) {
    border-collapse: collapse;

    @media ${customMedia.maxPhone} {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    @media ${media.phone} {
      border: 1px solid ${({ theme }) => theme.colors.main};
    }
  }

  table:not([class]) thead th {
    background-color: ${({ theme }) => theme.colors.watermelon};
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    border-collapse: collapse;
    display: table-cell;
    vertical-align: middle;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.main};

    > * {
      margin: 0;
      word-break: normal;
      font-size: 1em;
    }
  }

  table:not([class]) tbody td {
    border: 1px solid ${({ theme }) => theme.colors.main};
    border-collapse: collapse;
    display: table-cell;
    vertical-align: middle;
    padding: 10px;
    font-size: 0.857em;
    word-break: normal;

    @media ${customMedia.maxPhone} {
      white-space: break-spaces;
      max-width: 300px;
    }

    @media ${media.phone} {
      font-size: 1em;
    }
    > * {
      margin: 0;
    }
  }

  .alignright {
    float: right;
    margin-left: 1rem;
  }

  .alignleft {
    float: left;
    margin-right: 1rem;
  }

  .has-text-align-center {
    text-align: center;
  }

  .wp-block-quote {
    text-align: center;
    padding: 8px 0;
    em:not([class]) {
      display: block;
    }
  }

  ${({ mediumType }) =>
    mediumType &&
    css`
      *:not([class]) {
        font-weight: ${({ theme }) => theme.fontWeight.light};
        color: ${({ theme }) => theme.colors.main};
      }
    `}

  ${({ isThinTypo }) =>
    isThinTypo &&
    css`
      *:not([class]) {
        font-weight: 400;
      }
    `}

  ${({ smallerHeadings }) =>
    smallerHeadings &&
    css`
      #wysiwyg-content {
        padding-bottom: 60px;
      }

      strong:not([class]) {
        color: #333;
      }

      ul {
        padding-left: 10px;
        margin-left: 0;
      }

      ul li::before {
        color: #333;
      }
    `}

  ${({ smallerHeadings, wide }) =>
    (smallerHeadings || wide) &&
    css`
      *:not([class]) {
        text-align: justify;
      }

      h1:not([class]),
      h1:not([class]) * {
        font-size: 1.4em;
        text-align: left;

        @media ${media.phone} {
          font-size: 1.8em;
        }
      }

      h2:not([class]),
      h2:not([class]) * {
        font-size: 1.3em;
        text-align: left;
      }

      h3:not([class]),
      h3:not([class]) * {
        font-size: 1.2em;
        text-align: left;
      }

      h4:not([class]),
      h4:not([class]) *,
      h5:not([class]),
      h5:not([class]) *,
      h6:not([class]),
      h6:not([class]) * {
        font-size: 1.2em;
        text-align: left;
      }
    `}

  ${({ smallerLists }) =>
    smallerLists &&
    css`
      && ul li:not([class]) {
        margin-bottom: 0;
      }
    `}

  ${({ wide }) =>
    wide &&
    css`
      *:not([class]) {
        text-align: justify;

        &:not(:last-child) {
          margin-bottom: 1.5em;
        }
      }

      blockquote *:not([class]) {
        text-align: center;
      }

      blockquote + p {
        margin-bottom: 1.5rem;
      }

      img {
        margin: 2rem auto;
        width: 100%;
      }

      figure {
        margin: 2rem auto;
        width: 100%;
      }

      figure img {
        margin-bottom: 0.5rem;
      }

      figure iframe {
        width: 100%;
        height: 400px;
      }

      figure figcaption:not([class]) {
        margin-top: -0.5rem;
        font-size: 0.75em;
      }

      h1:not([class]),
      h2:not([class]),
      h3:not([class]),
      h4:not([class]),
      h5:not([class]),
      h6:not([class]) {
        margin-top: 2em;
        margin-bottom: 1.5em;
      }

      p strong:not([class]) {
        opacity: 0.85;
      }
    `}
`;

export { WysiwygStyled };
