import styled, { css } from 'styled-components';
import { space, color } from 'styled-system';

import { customMedia } from './../../common/MediaQueries';

const SectionStyled = styled.section`
  width: 100%;
`;

const SectionHeadStyled = styled.div`
  ${space}
  ${color}
  width: 100%;

  span {
    color: ${({ theme }) => theme.colors.red};
  }

  ${({ isMobileBorder }) =>
    isMobileBorder &&
    css`
      @media ${customMedia.maxPhone} {
        border-bottom: 1px solid currentColor;
      }
    `}
`;

const SectionBlockStyled = styled.div`
  ${space}
  ${color}
  width: 100%;

  mark {
    background-color: ${({ theme }) => theme.colors.watermelon};
    color: ${({ theme }) => theme.colors.white};
    padding: 0 4px;
    margin: 0 2px;
  }
`;

export { SectionStyled, SectionHeadStyled, SectionBlockStyled };
